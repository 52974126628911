<template>
  <div class="page">
    <el-row class="tool-bar">
      <el-col :span="12">
        <search-tool v-model="search" @input="$utils('search', getCene)"/>
      </el-col>
      <el-col :span="12" align="right">
        <el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/cene/create')" v-if="$utils('roleUser', 'Cenovnik', 'create')">{{ $t('global.novo') }}</el-button>
      </el-col>
    </el-row>
    <el-row>
      <!--Table-->
      <el-table v-if="cene.length"
                :data="cene"
                ref="table"
                :default-sort="sortBy"
                @sort-change="sort => $utils('changeSort', sort, getCene)"
                v-loading="gridLoad"
                border
                stripe>
        <el-table-column
                align="left"
                prop="naziv_cenovnika"
                :label="$t('cene.nazivCenovnika')"
                sortable="custom">
        </el-table-column>
        <el-table-column
                align="left"
                prop="naziv_linije"
                :label="$t('cene.nazivLinije')"
                sortable="custom">
        </el-table-column>
        <el-table-column
                align="center"
                prop="datum_od"
                :label="$t('cene.datumOd')"
                sortable="custom">
            <template slot-scope="scope">
                {{$utils('formatDate', scope.row.datum_od)}}
            </template>
        </el-table-column>
        <el-table-column
                align="center"
                prop="datum_do"
                :label="$t('cene.datumDo')"
                sortable="custom">
            <template slot-scope="scope">
                {{$utils('formatDate', scope.row.datum_do)}}
            </template>
        </el-table-column>
        <el-table-column
                align="left"
                prop="vrsta_cene"
                :label="$t('cene.vrstaCene')"
                sortable="custom">
        </el-table-column>
        <el-table-column
                align="center"
                prop="raspolozivo"
                :label="$t('cene.raspolozivo')"
                sortable="custom">
        </el-table-column>
        <el-table-column
                width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="$t('cene.dani')" placement="top" v-if="$utils('roleUser', 'Cenovnik', 'edit')">
              <el-button type="warning" icon="fas fa-calendar" @click="$utils('goTo', '/cene/dani/' + scope.row.id + '')" size="small"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Cenovnik', 'edit')">
              <el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/cene/' + scope.row.id + '')" size="small"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Cenovnik', 'delete')">
              <el-button type="danger" icon="fas fa-trash" @click="$delete('cenovnik', scope.row.id)" size="small"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
    </el-row>
    <br>
    <el-row>
      <pagination v-model="pagination" @input="getCene" ref="pagination"/>
    </el-row>
  </div>
</template>

<script>
export default {
	name: 'cene-list',
	data() {
		return {
			cene: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv_cenovnika',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('cene.cenovnici')
		});
		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getCene()]);
	},
	methods: {
		getCene() {
			this.gridLoad = true;
			return this.$get('cenovnik', this.$utils('getParams')).then(data => {
				this.cene = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
